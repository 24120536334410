<template>
  <div class="grid grid-cols-7 text-xs text-gray-500">
    <div v-for="(name, i) in daysOfWeek()" :key="i">{{ name }}</div>
  </div>
</template>

<script setup>
const dayjs = useDayjs();
const daysOfWeek = () => [0, 1, 2, 3, 4, 5, 6].map((d) => dayjs().day(d).format("ddd"));
</script>
